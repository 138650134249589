import * as React from 'react';
import { Link, graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  LBgSect,
  CPageNav,
  CFaqList,
  LContact,
} from '../../../components/_index';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="small"
        data={{
          title: {
            main: 'FAQ',
            sub: 'よくあるご質問',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/faq/kv.png',
              },
              imgSp: {
                src: '/assets/images/faq/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [],
            current: {
              label: 'よくあるご質問',
            },
          }}
        />
      </CJumbotron>
      <section className="u_mbExlarge l_sect">
        <LWrap exClass="u_mbExLarge">
          <CPageNav
            data={[
              {
                label: 'ご宿泊',
                link: {
                  href: '/faq/',
                },
              },
              {
                label: 'レストラン＆バー',
                link: {
                  href: '/faq/restaurants/',
                },
              },
              {
                label: 'ウエディング',
                link: {
                  href: '/faq/wedding/',
                },
              },
              {
                label: 'ご宴会・会議',
                link: {
                  href: '/faq/banquet/',
                },
              },
              {
                label: '館内施設',
                link: {
                  href: '/faq/facility/',
                },
              },
              {
                label: 'アクセス',
                link: {
                  href: '/faq/access/',
                },
              },
            ]}
          />
          <CFaqList
            hash=""
            data={[
              {
                q: 'レストランは予約が必要ですか?',
                a: (
                  <>
                    68F フレンチレストラン「ル シエール」とB1F 鉄板焼「よこはま」は、完全予約制ですので、事前のご予約が必要です。
                    <br />
                    70F スカイラウンジ「シリウス」、68F 日本料理「四季亭」、68F 中国料理「皇苑」 は完全予約制ではありませんが、ご予約をおすすめしております。
                    <br />
                    なお、朝食はご予約を承っておりませんので直接ご来店ください。
                  </>
                ),
              },
              {
                q: '車で行きたいのですが、駐車場はありますか？また、駐車料金はいくらですか?',
                a: (
                  <>
                    ホテル専用スペースではございませんが、駐車場がございます。
                    <br />
                    詳しくは
                    <a href="/about/access/car/">こちら</a>
                    をご覧ください。
                  </>
                ),
              },
              {
                q: '子供と利用したいのですが。',
                a: (
                  <>
                    ほとんどのレストランはお子様とご一緒にご利用いただけますが、下記の通り一部ご利用いただけない場合もございます。
                    <br />
                    ●70F スカイラウンジ「シリウス」
                    <br />
                    17:30以降、20歳未満の方のご利用はご遠慮いただいております。
                    <br />
                    ●68F フレンチレストラン「ル シエール」
                    <br />
                    小学生未満のお子様の一般席でのご利用はご遠慮いただいております。個室でのご利用は可能です。
                  </>
                ),
              },
              {
                q: '子供向けのメニューはありますか?',
                a: (
                  <>
                    68F 日本料理「四季亭」、68F 中国料理「皇苑」、B1F
                    鉄板焼「よこはま」にてご用意しております。
                  </>
                ),
              },
              {
                q: 'レストランでパーティーをしたいのですが。',
                a: (
                  <>
                    下記のレストラン＆バーにて、パーティープランをご用意しております。詳しくはお問合せください。
                    <br />
                    ●70F スカイラウンジ「シリウス」
                    <br />
                    ●68F フレンチレストラン「ル
                    シエール」、日本料理「四季亭」、中国料理「皇苑」
                    は、個室をご利用いただけるプランをご用意しております。
                  </>
                ),
              },
              {
                q: '記念日などで利用する場合にどのようなサービスがありますか?',
                a: (
                  <>
                    ●レストランをご利用の場合
                    <br />
                    記念撮影のサービスをしております。
                    <br />
                    また、ケーキやお花などがセットになったプランもございますので、詳しくはお問合せください。
                    <ul className="c_noteList">
                      <li>
                        バースデーケーキなど、各種手配も承りますのでご予約の際にお申付けください。
                      </li>
                    </ul>
                    ●ご宿泊をご利用の場合
                    <br />
                    ディナー付きプランやケーキ付きプランをご用意しておりますので、ぜひご利用ください。
                    <br />
                    その他、花束などのオプションやルームサービスにてケーキやシャンパンのご手配も承ります。
                    <br />
                    <Link to="/anniversary/stay_plan/#option">＞宿泊プランオプション</Link>
                    <br />
                    <Link to="/stay/room_service/">＞ルームサービス</Link>
                    <ul className="c_noteList">
                      <li>
                        サプライズプレゼントをお送り・お持込みの際は、<Link to="/faq/#q">「Q.事前に荷物を送りたいのですが。」</Link>をご覧ください。
                      </li>
                      <li>
                        お送りいただいたお荷物でのお部屋の装飾サービスは行っておりません。
                      </li>
                    </ul>
                  </>
                ),
              },
              {
                q: '喫煙席はありますか?',
                a: (
                  <>
                    「神奈川県公共的施設における受動喫煙防止条例」の施行に伴い、2010年4月1日より当ホテルにおいてはレストラン・バーを全席禁煙とさせていただいております。
                    <br />
                    タバコを吸われるお客様は、喫煙所（ホテル棟1F・68F）をご利用ください。
                  </>
                ),
              },
              {
                q: 'サービス料はいくらですか?',
                a: <>本体価格の15%を頂戴いたします。</>,
              },
              {
                q: '食べ物にアレルギーや食事制限のある人がいます。対応できますか?',
                a: (
                  <>
                    食物アレルギー、持病、宗教、妊娠、授乳中などの食事制限によりお召しあがりいただけない食材がある場合は、事前にお申付けください。
                    <br />
                    <ul className="c_noteList">
                      <li>
                        すべてのアレルギー等に対応できるわけではございませんので、予めご了承ください。
                      </li>
                    </ul>
                  </>
                ),
              },
              {
                q: 'wifiは利用できますか?',
                a: (
                  <>
                    ホテルご利用のお客様は無料でご利用いただけます。
                    <br />
                    詳しくは
                    <Link to="/facilities/business/">こちら</Link>
                    をご覧ください。
                  </>
                ),
              },
            ]}
          />
        </LWrap>
      </section>
      <LContact
        exClass="u_telUnit_end"
        tel={[
          {
            title: '代表',
            number: '045-221-1111',
          },
        ]}
      />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
